// eslint-disable-next-line import/no-cycle
import { IAttendee, IEvent } from '../events';

export enum MatchStatus {
  REPORTED = 'REPORTED',
  UNMATCHED = 'UNMATCHED',
  VALID = 'VALID',
}

export interface EventConnectMatch {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  status: MatchStatus;
  senderId: number;
  receiverId: number;
  eventId: number;
  user: IAttendee;
  showAvatar: boolean;
  event: IEvent;
  chatChannelId: string;
}
