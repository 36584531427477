export enum Interest {
  Cryptocurrency = 'Cryptocurrency',
  Party = 'Party',
  Music = 'Music',
  Festival = 'Festival',
  Netflix = 'Netflix',
  Engineering = 'Engineering',
  Technology = 'Technology',
  Drawing = 'Drawing',
  Printing = '3D printing',
  Radio = 'Radio',
  Scrapbook = 'Scrapbook',
  Acting = 'Acting',
  BatonTwirling = 'Baton twirling',
  BoardGames = 'Board games',
  ShootingRange = 'Shooting Range',
  BookRestoration = 'Book restoration',
  Cabaret = 'Cabaret',
  Calligraphy = 'Calligraphy',
  CandleMaking = 'Candle making',
  ComputerProgramming = 'Computer programming',
  CoffeeRoasting = 'Coffee roasting',
  Cooking = 'Cooking',
  Colouring = 'Colouring',
  Cosplaying = 'Cosplaying',
  Couponing = 'Couponing',
  CreativeWriting = 'Creative writing',
  Crocheting = 'Crocheting',
  Cryptography = 'Cryptography',
  Dance = 'Dance',
  DigitalArts = 'Digital arts',
  Drama = 'Drama',
  DIY = 'DIY',
  Electronics = 'Electronics',
  Embroidery = 'Embroidery',
  Fashion = 'Fashion',
  FlowerArranging = 'Flower arranging',
  ForeignLanguageLearning = 'Foreign language learning',
  Gaming = 'Gaming',
  TabletopGames = 'Tabletop games',
  RoleplayingGames = 'Roleplaying games',
  Gambling = 'Gambling',
  Genealogy = 'Genealogy',
  Glassblowing = 'Glassblowing',
  Gunsmithing = 'Gunsmithing',
  Homebrewing = 'Homebrewing',
  Iceskating = 'Ice skating',
  JewelryMaking = 'Jewelry making',
  JigsawPuzzles = 'Jigsaw puzzles',
  Juggling = 'Juggling',
  Knapping = 'Knapping',
  Knitting = 'Knitting',
  Kabaddi = 'Kabaddi',
  KnifeMaking = 'Knife making',
  Lacemaking = 'Lacemaking',
  Lapidary = 'Lapidary',
  LeatherCrafting = 'Leather crafting',
  LegoBuilding = 'Lego building',
  Lockpicking = 'Lockpicking',
  Machining = 'Machining',
  Macrame = 'Macrame',
  Metalworking = 'Metalworking',
  Magic = 'Magic',
  ModelBuilding = 'Model building',
  Origami = 'Origami',
  Painting = 'Painting',
  PlayingMusicalInstruments = 'Playing musical instruments',
  Pet = 'Pet',
  Poi = 'Poi',
  Pottery = 'Pottery',
  Puzzles = 'Puzzles',
  Quilting = 'Quilting',
  Reading = 'Reading',
  Scrapbooking = 'Scrapbooking',
  Sculpting = 'Sculpting',
  Sewing = 'Sewing',
  Singing = 'Singing',
  Sketching = 'Sketching',
  Soapmaking = 'Soapmaking',
  Sports = 'Sports',
  StandUp = 'Stand-up comedy',
  Sudoku = 'Sudoku',
  TableTennis = 'Table tennis',
  Taxidermy = 'Taxidermy',
  VideoGaming = 'Video gaming',
  WatchingMovies = 'Watching movies',
  WebSurfing = 'Web surfing',
  Whittling = 'Whittling',
  WoodCarving = 'Wood carving',
  Woodworking = 'Woodworking',
  WorldBuilding = 'World Building',
  Writing = 'Writing',
  Yoga = 'Yoga',
  YoYoing = 'Yo-yoing',
  AirSports = 'Air sports',
  Archery = 'Archery',
  Astronomy = 'Astronomy',
  Backpacking = 'Backpacking',
  BaseJumping = 'Base jumping',
  Baseball = 'Baseball',
  Basketball = 'Basketball',
  Beekeeping = 'Beekeeping',
  BirdWatching = 'Bird watching',
  Blacksmithing = 'Blacksmithing',
  BoardSports = 'Board sports',
  Bodybuilding = 'Bodybuilding',
  BraziliaJiuJitsu = 'Brazilian jiu-jitsu',
  Community = 'Community',
  Cycling = 'Cycling',
  Dowsing = 'Dowsing',
  Driving = 'Driving',
  Fishing = 'Fishing',
  Finance = 'Finance',
  Investment = 'Investment',
  FlagFootball = 'Flag football',
  Flying = 'Flying',
  FlyingDisc = 'Flying disc',
  Foraging = 'Foraging',
  Gardening = 'Gardening',
  Geocaching = 'Geocaching',
  GhostHunting = 'Ghost hunting',
  Graffiti = 'Graffiti',
  Handball = 'Handball',
  Hiking = 'Hiking',
  Hooping = 'Hooping',
  HorsebackRiding = 'Horseback riding',
  Hunting = 'Hunting',
  InlineSkating = 'Inline skating',
  Jogging = 'Jogging',
  Kayaking = 'Kayaking',
  KiteFlying = 'Kite flying',
  Kitesurfing = 'Kitesurfing',
  Larping = 'Larping',
  Letterboxing = 'Letterboxing',
  MetalDetecting = 'Metal detecting',
  MotorSports = 'Motor sports',
  MountainBiking = 'Mountain biking',
  Mountaineering = 'Mountaineering',
  MushroomHunting = 'Mushroom hunting',
  Mycology = 'Mycology',
  Netball = 'Netball',
  NordicSkating = 'Nordic skating',
  Orienteering = 'Orienteering',
  Paintball = 'Paintball',
  Parkour = 'Parkour',
  Photography = 'Photography',
  Polo = 'Polo',
  Rafting = 'Rafting',
  Rappelling = 'Rappelling',
  RockClimbing = 'Rock climbing',
  RollerSkating = 'Roller skating',
  Rugby = 'Rugby',
  Running = 'Running',
  Sailing = 'Sailing',
  SandArt = 'Sand art',
  Scouting = 'Scouting',
  ScubaDiving = 'Scuba diving',
  Sculling = 'Sculling',
  Rowing = 'Rowing',
  Shopping = 'Shopping',
  Skateboarding = 'Skateboarding',
  Skiing = 'Skiing',
  SkimBoarding = 'Skim Boarding',
  Skydiving = 'Skydiving',
  Slacklining = 'Slacklining',
  Snowboarding = 'Snowboarding',
  StoneSkipping = 'Stone skipping',
  Surfing = 'Surfing',
  Swimming = 'Swimming',
  Taekwondo = 'Taekwondo',
  TaiChi = 'Tai chi',
  UrbanExploration = 'Urban exploration',
  Vacation = 'Vacation',
  VehicleRestoration = 'Vehicle restoration',
  WaterSports = 'Water sports',
}
