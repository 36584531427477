/* eslint-disable import/no-cycle */
import { IEvent, IAttendee, EventParticipantStatus } from '../events';

export enum RequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface EventConnectRequest {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  senderId: number;
  status: RequestStatus;
  receiverId: number;
  eventId: number;
  sender: IAttendee;
  receiver: IAttendee;
  event: IEvent;
}

export interface IConnectRequestResponse {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  senderId: number;
  status: RequestStatus;
  receiverId: number;
  eventId: number;
  matchId: number;
  requestsCount: number;
}

export interface IParticipation {
  status?: EventParticipantStatus;
  connect: boolean;
  showAvatar?: boolean;
}
export interface IConnectRequest {
  receiverId: number;
  like: boolean;
}
