export enum IFeature {
  REQUEST_LIMIT_PER_DAY = 'requestLimitPerDay',
  MATCHES_EXPIRES = 'matchesExpires',
  SEE_REQUESTS = 'seeLikes',
}

export enum RequestLimit {
  FIVE_PER_DAY = 5,
  UNLIMITED = 0,
}

export interface ITier {
  [IFeature.REQUEST_LIMIT_PER_DAY]: RequestLimit;
  [IFeature.MATCHES_EXPIRES]: boolean;
  [IFeature.SEE_REQUESTS]: boolean;
}

export enum SubscriptionStatus {
  VALID = 'VALID',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export enum SubscriptionTier {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
}
