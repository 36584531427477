/* eslint-disable import/no-cycle */
import { EventConnectMatch, EventConnectRequest } from '../connect';
import { IEventParticipant, LiveReview, Venue } from '../events';
import { EventTag } from '../events/event-tag';
import { Address } from '../shared';
import { Picture } from '../shared/picture';
import { CurrencyCodes } from './currency';
import { Interest } from './interest';
import { NotificationPreferences } from './notification-preferences';
import { ITier, SubscriptionStatus, SubscriptionTier } from './subscription';
import { Wallet } from './wallet';

export interface EventFilter {
  selectedTags?: EventTag[];
}

export interface UserPreferences {
  filters?: EventFilter;
  occupation?: string;
  description?: string;
  locale: string;
  currency?: CurrencyCodes;
  interests?: Interest[];
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
}

export interface SendSmsDTO {
  phone?: string;
  email?: string;
}

export interface VerificationCodeDTO {
  phone?: string;
  email?: string;
  code: string;
}

export interface Subscription {
  id: number;
  stripeSubscriptionId: string;
  paymentIntentId: string;
  paymentMethodId: string;
  invoiceId: string;
  orderConfirmationId: string;
  status: SubscriptionStatus;
  tier: SubscriptionTier;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  userId: number;
  user: User;
}

export interface DeviceToken {
  token: string;
  timestamp: Date;
}

export interface AvatarSettings {
  event: boolean;
  connect: boolean;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  user: Pick<User, 'id' | 'firstname' | 'lastname' | 'email' | 'phone' | 'stripeId'>;
}

export interface User {
  id: number;
  firstname?: string;
  lastname?: string;
  alias?: string;
  logo?: Picture;
  email: string;
  phone: string;
  latitude: number;
  longitude: number;
  radius: number;
  avatarSettings?: AvatarSettings;
  preferredAddress?: Address;
  birthdate?: Date;
  preferences?: UserPreferences;
  deviceToken?: DeviceToken;
  stripeId: string;
  wallet?: Wallet;
  isVerified: boolean;
  pictures: Picture[];
  gender?: Gender;
  cover?: Picture;
  sessionToken: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  notificationPreferences?: NotificationPreferences;
  submittedEvents?: Event[];
  attendedEvents?: IEventParticipant[];
  venues?: Venue[];
  notifications?: Notification[];
  liveReviews?: LiveReview[];
  subscription?: Subscription;
  requestReceived?: EventConnectRequest[];
  matchesSent?: EventConnectMatch[];
  matchesReceived?: EventConnectMatch[];
  reports?: Report[];
  tier?: ITier;
  avatar?: Picture;
  showAvatar?: boolean;
}
