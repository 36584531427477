import { Cart } from './cart';

import { TicketSetting } from '../tickets/ticket-setting';

export interface ProcessOrderDTO {
  paymentIntentId: string;
  cartId: number;
}

export interface TicketResponse {
  tickets: Ticket[];
  orderConfirmationIds: string[];
}

export enum TicketStatus {
  Valid = 'VALID',
  Cancelled = 'CANCELLED',
}
export interface Ticket {
  id: number;
  title?: string;
  slug: string;
  qrCodeId: string;
  validityDate: Date;
  ticketSettingId: number;
  orderId: number;
  ticketSetting: TicketSetting;
  order: Order;
  eventId: number;
  event: Event;
  scannedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
  status: TicketStatus;
}

export interface Order {
  id: number;
  orderConfirmationId: string;
  amount: number;
  paymentMethodId?: string;
  paymentIntentId?: string;
  cartId: number;
  cart?: Cart;
  tickets?: Ticket[];
  userId: number;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
}
