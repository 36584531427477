import { IEventTag } from '../models/event-tag.model';
import { EventTag } from '../types';

export const _filters: IEventTag[] = [
  {
    id: EventTag.Party,
    name: 'Party',
    activateLink: '/browse/paris/music/party',
    clearLink: '/browse/paris',
    icon: 'la:cocktail',
    active: false,
  },
  // {
  //   id: EventTag.FashionWeek,
  //   name: 'Fashion Week',
  //   activateLink: '/browse/paris/music/fashion',
  //   clearLink: '/browse/paris',
  //   icon: 'system-uicons:diamond',
  //   active: false,
  // },
  {
    id: EventTag.Networking,
    name: 'Networking',
    activateLink: '/browse/paris/culture/networking',
    clearLink: '/browse/paris',
    icon: 'ic:baseline-people',
    active: false,
  },
  {
    id: EventTag.DJ,
    name: 'DJ Set',
    activateLink: '/browse/paris/music/dj',
    clearLink: '/browse/paris',
    icon: 'jam:dj',
    active: false,
  },
  {
    id: EventTag.Art,
    name: 'Art',
    activateLink: '/browse/paris/culture/art',
    clearLink: '/browse/paris',
    icon: 'mdi:art',
    active: false,
  },
  {
    id: EventTag.Sports,
    name: 'Sports',
    activateLink: '/browse/paris/culture/sport',
    clearLink: '/browse/paris',
    icon: 'fluent:sport-16-filled',
    active: false,
  },
  {
    id: EventTag.LiveShows,
    name: 'Live Shows',
    activateLink: '/browse/paris/music/playback',
    clearLink: '/browse/paris',
    icon: 'ic:outline-audiotrack',
    active: false,
  },
  {
    id: EventTag.Game,
    name: 'Game',
    activateLink: '/browse/paris/culture/comedy',
    clearLink: '/browse/paris',
    icon: 'ion:dice', // Changed to a grinning emoji icon
    active: false,
  },

  {
    id: EventTag.Outdoor,
    name: 'Outdoor',
    activateLink: '/browse/paris/culture/podcast',
    clearLink: '/browse/paris',
    icon: 'icon-park-outline:outdoor', // Changed to a microphone icon
    active: false,
  },
];
