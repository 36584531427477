export interface SendRequestDTO {
  cartId: number;
}

export interface EventRequest {
  id: number;
  status: EventRequestStatus;
  paymentStatus: PaymentStatus;
  paymentIntentId: string;
  orderId?: number;
  eventId: number;
  userId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  reviewedAt: Date | null;
}

export enum PaymentStatus {
  REFUNDED = 'REFUNDED',
  ON_HOLD = 'ON_HOLD',
  DEBITED = 'DEBITED',
  RELEASED = 'RELEASED',
}

export enum EventRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
