export enum PromoCodeType {
  Percentage = 'PERCENTAGE',
  FlatAmount = 'FLAT_AMOUNT',
}

export interface PromoCode {
  id: number;
  name: string;
  value: number;
  type: PromoCodeType;
  eventId: number;
  startDate?: Date;
  endDate?: Date;
  createdAt: Date;
  updatedAt: Date;
}
