import { Address } from '../shared/address';
// eslint-disable-next-line import/no-cycle
import { User } from '../users/user';

export enum SubEntityType {
  Organization = 'organization',
}

export enum SubscriptionType {
  Organizer = 'ORGANIZER',
}

export interface LocationDTO {
  placeId: string;
  radius?: number;
}

export interface CreateSubDTO {
  email: string;
  entityId: number;
  entityType: SubEntityType;
  subscriptionType: SubscriptionType;
  locations: LocationDTO[];
  emailEnabled?: boolean;
  pushEnabled?: boolean;
}

export interface UpdateSubDTO {
  email: string;
  entityId: number;
  entityType: SubEntityType;
  subscriptionType: SubscriptionType;
  locations?: LocationDTO[];
  emailEnabled?: boolean;
  pushEnabled?: boolean;
}

export interface Location {
  id: number;
  placeId: string;
  latitude: number;
  longitude: number;
  radius?: number;
  address: Address;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface NotificationSubscriptionLocation {
  subscriptionId: number;
  locationId: number;
  subscription?: NotificationSubscription;
  location?: Location;
}

export interface NotificationSubscription {
  id: number;
  entityId: number;
  entityType: SubEntityType;
  subscriptionType: SubscriptionType;
  locations: NotificationSubscriptionLocation[];
  userId?: number;
  email: string;
  emailEnabled: boolean;
  pushEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  user?: User;
}
